<template>
	<div class="playerWrp" ref="rt">
		<!-- <audio ref="aud" :src="require('@/assets/music2.mp3')" controls></audio> -->
		<div class="hd">
			<div class="btn" @click="onPlayClick">
				<span v-if="!playing" class="ic-play-icon"></span>
				<span v-else class="ic-pause"></span>
			</div>
			<div class="namewrp">
				<div class="sub">Донорам</div>
				<div class="title">{{compdata.title}}</div>
			</div>
		</div>
		<wavesurfer ref="surf" :src="pathFile(compdata.audio)" :options="options"></wavesurfer>
		<div style="display: none">{{pathFile(compdata.audio)}}</div>
	</div>
</template>

<script>
import {Bus} from '@/main.js'
export default {
	name: 'AudioPlayer',
	props: ['compdata'],
	components: {
	},
	data() {
		return {
			canvWdith: 20,
			playing: false,
			options: {
				fillParent: true,
				barWidth: 2,
				height: 60,
				barGap: 1,
				progressColor: '#F4A700',
				cursorColor: '#F4A70000',
			},
		}
	},
	methods: {
		onPlayClick(){
			switch (this.playing) {
				case true:
					this.$refs.surf.waveSurfer.pause();
					this.playing = false;
					break;
				case false:
					Bus.$emit('onPlayerPlay');
					this.$refs.surf.waveSurfer.play();
					this.playing = true;
					break;
			}
		}
	},
	created () {
	},
	mounted () {
		Bus.$on('onPlayerPlay', () => {
			this.$refs.surf.waveSurfer.pause();
			this.playing = false;
		});
	},
	destroyed () {
	},
};
</script>

<style lang="scss">
.playerWrp {
	padding: 16px 0;
	margin-top: 20px 0 16px;
	.hd{
		display: flex;
		margin-bottom: 16px;
		.btn{
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: #F49E15;
			margin-right: 16px;
			cursor: pointer;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			.ic-play-icon{
				margin-left: 3px;
			}
		}
		.namewrp{
			.sub{
				font-size: 13px;
				line-height: 15px;
				color: #8E8E93;
				margin-bottom: 4px;
			}
			.title{
				font-size: 16px;
				line-height: 19px;
				color: #48484A;
			}
		}
	}
	.temp {
		height: 88px;
		background-color: rgb(177, 177, 177);
		color: #b1b1b1;
	}
}
</style>
<template>
	<div class="faqComponent">
		<template v-if="compdata">
			<div
				v-for="(item, index) in compdata.list"
				:key="index"
				class="item"
				:class="{ opened: currentIndex == index }"
			>
				<div class="hd" @click="onHdClick(index)" :ref="`item${index}`">
					<p class="caption" v-html="item.title"></p>
					<span class="ic-close-square"></span>
				</div>

				<slide-up-down :active="currentIndex == index" :duration="300">
					<div class="body" v-html="item.text"></div>
				</slide-up-down>

			</div>
		</template>
		<div v-else>Empty list data</div>
	</div>
</template>

<script>
export default {
	name: "FaqComponent",
	props: ["compdata"],
	data() {
		return {
			currentIndex: null,
		};
	},
	methods: {
		onHdClick(index) {
			if (typeof this.currentIndex == "number") {
				if (index == this.currentIndex) this.currentIndex = null;
				else {
					this.currentIndex = null;
					setTimeout(() => {
						this.currentIndex = index;
					}, 300);
				}
			} else {
				this.currentIndex = null;
				setTimeout(() => {
					this.currentIndex = index;
				}, 300);
			}
		},
		moveScroll(domElement, lastYpos) {
			let post = domElement.getBoundingClientRect().y;
			let delta = window.scrollY + (lastYpos - post);
			window.scroll(0, delta);
		},
	},
};
</script>

<style lang="scss">
</style>
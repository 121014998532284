<template>
	<div class="layoutWrp">
		<MobHeaderMenu
			@menuswitch="$emit('menuswitch')"
			style="position: static"
		></MobHeaderMenu>

		<transition name="fadefast" mode="out-in">
			<div v-if="!loaded" class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
			<div v-if="loaded" :key="'pagecontent'">
				<section class="faqPageSection">
			<div class="twoCol">
				<div class="l">
					<div class="topHd">
						<router-link :to="{name: 'home', params: {locale: $i18n.locale == 'uk' ? null : $i18n.locale}}" class="backBtn">
							<span class="ic-arrow-long-left"></span> {{$t('menu.mainPage')}}
						</router-link>
					</div>

					<left-join-us v-if="isSighUp"></left-join-us>

					<div v-if="tags" class="tagsField">
						<router-link
							v-for="(item, index) in tags"
							:key="index"
							:to="{ path: item.url }"
							class="tagItem"
							>{{ item.name }}</router-link
						>
					</div>

				</div>
				<div class="r">
					<h1>{{pageData.translate.title}}</h1>
					<div class="tabsDiv">
						<div class="item" :class="{active: tabIndex==0}" @click="tabIndex=0"><span class="ic-posts"></span> {{$t('read')}}</div>
						<div class="item" v-if="FaqAudioExist" :class="{active: tabIndex==1}" @click="tabIndex=1"><span class="ic-wave"></span> {{$t('listen')}}</div>
					</div>
					<div class="articleWrp" v-if="tabIndex == 0">
						<faq-component :compdata="textFaqData"></faq-component>
					</div>
					<div class="articleWrp" v-if="tabIndex == 1">
						<template v-for="(item, index) in audioFaqData">
							<audio-player v-if="item.audio" :key="index" :compdata="item"></audio-player>
						</template>
					</div>


					<!-- <question-form :compdata="constructor['questions-form']"></question-form> -->
				</div>
			</div>
		</section>
			</div>
		</transition>
	</div>
</template>

<script>
import faqComponent from '../components/constructor/faqComponent.vue';
import audioPlayer from '../components/constructor/audioPlayer.vue';
import LeftJoinUs from "@/components/constructor/LeftJoinUs.vue";
// import QuestionForm from "@/components/constructor/QuestionForm.vue";
import MobHeaderMenu from "@/components/common/MobHeaderMenu.vue";
export default {
	components: { 
		faqComponent,
		audioPlayer,
		LeftJoinUs,
		MobHeaderMenu
	},
	data() {
		return {
			tabIndex: 0,
			pageData: null,
			constructor: null,
			loaded: false,
			faq_data: null,
		}
	},
	computed: {
		city() {
			// TODO city
			return this.pageData.translate.city || null;
		},
		donationsCount() {
			// TODO donationsCount
			return this.pageData.model.donations || null;
		},
		tags() {
			return this.pageData.model.menu_items || null;
		},
		youtube() {
			return this.pageData.model.youtube || null;
		},
		isShare(){
			return this.pageData.model.is_share_block == 1 ? true : false;
		},
		isSighUp(){
			return this.pageData.model.is_sign_up_block == 1 ? true : false;
		},
		FaqAudioExist(){
			let n = 0;
			this.audioFaqData.forEach(item => {
				if(item.audio != null) n++;
			});
			return n ? true : false;
		},

		textFaqData(){
			let faqlist = [];
			this.faq_data.forEach(item => {
				let faqitem = {
					title: item.question,
					text: item.answer
				};
				faqlist.push(faqitem)
			})
			return {
				list: faqlist,
			}
		},
		audioFaqData(){
			let faqlist = [];
			this.faq_data.forEach(item => {
				let faqitem = {
					title: item.question,
					audio: item.audio
				};
				faqlist.push(faqitem)
			})
			return faqlist
		},
	},
	created() {
		this.axios
			.post("/api/page/get-by-slug", {
				lang: this.$i18n.locale,
				slug: `faq`,
			})
			.then((response) => {
				this.pageData = response.data.data;
				this.constructor = this.pageData.constructor;
				document.title = this.pageData.translate.title;
				this.faq_data = this.pageData.faq_data;
				this.loaded = true;
			})
			.catch(() => {
				this.$router.replace({ name: "error404" });
			});
	},
}
</script>

<style lang="scss">
@import "../scss/article.scss";
.layoutWrp {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	background: #f8f8f8;
}
.faqPageSection {
	background: #F8F8F8;
	flex-grow: 1;
	padding: 24px 48px 64px;
	@media (max-width: 765px) {
		padding: 24px 24px 64px;
	}
	@media (max-width: 575px) {
		padding: 24px 16px 64px;
	}
	.twoCol {
		display: flex;
		@media (max-width: 1365px) {
			flex-wrap: wrap;
		}
		.l {
			width: 550px;
			padding-right: 108px;
			box-sizing: border-box;
			flex-shrink: 0;
			@media (max-width: 1365px) {
				width: 100%;
				padding-right: 0px;
			}
		}
		.r {
			flex-grow: 1;
			@media (max-width: 1365px) {
				width: 100%;
			}
		}
	}
	.tagsField {
		.tagItem {
			height: 38px;
		}
	}
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 46px;
		line-height: 100%;
		color: #aeaeb2;
		margin: 48px 0 16px;
		@media (max-width: 1119px) {
			font-size: 36px;
		}
		@media (max-width: 375px) {
			font-size: 30px;
		}
	}
	.tabsDiv {
		margin: 0 0 24px;
		display: flex;
		// flex-wrap: wrap;
		box-shadow: 0 -1px 0 0px inset #d9d9d9;
		.item {
			cursor: pointer;
			height: 54px;
			padding: 0 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 100%;
			color: #8e8e93;
			@media (max-width: 575px) {
				padding: 0;
				width: 50%;
			}
			[class^="ic-"],
			[class*=" ic-"] {
				font-size: 18px;
				margin-right: 10px;
				color: #c7c7cc;
			}
			&Lhover {
			}
			&.active {
				color: #000;
				box-shadow: 0 -2px 0 0px inset #f4a700;
				[class^="ic-"],
				[class*=" ic-"] {
					color: #f4a700;
				}
			}
		}
	}
}
</style>
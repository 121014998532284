<template>
	<div>
		<div class="dotarionBrnTitle">{{$t("signOpnile")}}</div>
		<a v-if="$store.getters.formLnk" :href="linkUrl" target="_blank" class="donationBtn"><span class="ic-hand-1"></span>{{$t('getVisit')}}</a>
	</div>
</template>

<script>
export default {
	name: 'LeftJoinUs',
	props: ['compdata', 'url'],
	computed: {
		linkUrl() {
			return this.url || this.$store.getters.formLnk;
		}
	},
}
</script>

<style>

</style>